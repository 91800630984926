import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiService {
  headers_object;

  constructor(private http: HttpClient) {
    //let token = localStorage.getItem('accessToken') || null;
    //this.headers_object = new HttpHeaders().set("Authorization", "Bearer " + token);
  }

  getRequest(url) {
    return this.http.get(environment.url + url, {
      headers: this.headers_object,
    });
  }

  deleteRequest(url) {
    return this.http.delete(environment.url + url, {
      headers: this.headers_object,
    });
  }

  postRequest(url, data) {
    return this.http.post(environment.url + url, data, {
      headers: this.headers_object,
    });
  }

  updateRequest(url, data) {
    //return this.http.put(environment.baseUrl + 'country/updateCountry/' + id, data);
    return this.http.put(environment.url + url, data, {
      headers: this.headers_object,
    });
  }

  uploadImage(url, fileToUpload) {
    const uploadData: FormData = new FormData();
    if (fileToUpload) {
      uploadData.append('file', fileToUpload, fileToUpload.name);
    }
    return this.http.post(environment.url + url, uploadData, {
      headers: this.headers_object,
    });
  }

  public get getUserFromLocal(): any {
    return JSON.parse(localStorage.getItem('accessToken'));
  }
}
