import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SharedService } from '../services/shared.service';
declare var $ : any;
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  environment = environment;
  constructor(private fb: FormBuilder, private apiService: ApiService,  private router: Router,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: '',
      password: ''
    });
    $('#loading').hide(); 
  }

  onSubmit(form){
    $('#loading').show(); 
    this.apiService.postRequest('user/login',form.value).subscribe((data: any) => {
      
      localStorage.setItem('accessToken', data.data);
      this.router.navigate(['/layout/dashboard']);
    },(err) => {
      $('#loading').hide(); 
      if(err && err.hasOwnProperty('error'))
        this.sharedService.errorMsg(err.error.message);
      else
        this.sharedService.errorMsg(err.error.message);
    })
  }

}
